<template>
    <div>

        <h5 class="scopeTitle">Contract Scope</h5>
        <b-dropdown ref="template-dropright" dropright text="Templates" variant="outline-primary" class="ml-2 mb-1"
            size="sm">
            <b-dropdown-item v-for="(template, index) in templates" :key="template.name"
                @click.prevent="enterTemplateTxt(index)">{{ template.name }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
        </b-dropdown>

        <b-button-toolbar v-if="editor">

            <b-button-group size="sm" class="mr-1 mb-1">
                <b-button variant="outline-secondary" @click="editor.chain().focus().setFontSize('16px').run()"
                    :class="{ 'active': editor.isActive('textStyle', { fontSize: '16px' }) }">
                    <font-awesome-icon icon="paragraph" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().setFontSize('12px').run()"
                    :class="{ 'active': editor.isActive('textStyle', { fontSize: '12px' }) }">
                    <font-awesome-icon icon="minus" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().setFontSize('20px').run()"
                    :class="{ 'active': editor.isActive('textStyle', { fontSize: '20px' }) }">
                    <font-awesome-icon icon="plus" />
                </b-button>

            </b-button-group>

            <b-button-group size="sm" class="mb-1 mr-1">
                <b-button variant="outline-secondary" @click="editor.chain().focus().toggleBold().run()"
                    :class="{ 'active': editor.isActive('bold') }">
                    <font-awesome-icon icon="bold" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().toggleItalic().run()"
                    :class="{ 'active': editor.isActive('italic') }">
                    <font-awesome-icon icon="italic" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().toggleStrike().run()"
                    :class="{ 'active': editor.isActive('strike') }">
                    <font-awesome-icon icon="strikethrough" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().toggleUnderline().run()"
                    :class="{ 'active': editor.isActive('underline') }">
                    <font-awesome-icon icon="underline" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().toggleHighlight().run()"
                    :class="{ 'active': editor.isActive('highlight') }">
                    <font-awesome-icon icon="highlighter" />  <font-awesome-icon style="color:#fcf8e3" :icon="['fas', 'square']" />
                </b-button>
            </b-button-group>

            <b-button-group size="sm" class="mb-1">
                <b-button variant="outline-secondary" @click="editor.chain().focus().setColor('#f44336').run()"
                    :class="{ 'is-active': editor.isActive('textStyle', { color: '#f44336' }) }">
                    Red <font-awesome-icon style="color:#f44336" :icon="['fas', 'square']" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().setColor('#3688f4').run()"
                    :class="{ 'is-active': editor.isActive('textStyle', { color: '#3688f4' }) }">
                    Blue <font-awesome-icon style="color:#3688f4" :icon="['fas', 'square']" />
                </b-button>
                <b-button variant="outline-secondary" @click="editor.chain().focus().setColor('#36f4a2').run()"
                    :class="{ 'is-active': editor.isActive('textStyle', { color: '#36f4a2' }) }">
                    Green <font-awesome-icon style="color:#36f4a2" :icon="['fas', 'square']" />
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <div class="editor">
            <editor-content :editor="editor" ref="scopeeditor" />
        </div>

    </div>
</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from '@luciusa/extension-font-size'
import TextColor from '@tiptap/extension-color'

export default {
    name: "editorScope",
    components: {
        EditorContent
    },
    props: {
        editing: Boolean,
        contractData: Object
    },
    computed: {
        contract() {
            if (!this.editing) {
                return this.$state.newContract.contract
            } else {
                return this.contractData
            }
        },
        customer() {
            return this.$state.newContract.customer
        }
    },
    data() {
        return {
            editor: null,
            templates: []
        }
    },
    mounted() {

        this.editor = new Editor({
            content: this.contract.scopeText,
            onUpdate: () => {
                // HTML
                this.contract.scopeText = this.editor.getHTML()
            },
            autoFocus: true,
            extensions: [
                StarterKit,
                Highlight,
                Underline,
                TextStyle,
                FontSize,
                TextColor
            ]

        })

    },
    created() {
        this.getTemplates()
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
        async getTemplates() {
            console.log("calling getTemplates")
            try {
                let res = await this.$axios.get(`/templates`, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(res.data)
                this.templates = res.data
                this.ready = true

            } catch (err) {
                console.error(err)
            }

        },
        enterTemplateTxt(templateIndex) {
            this.editor.commands.setContent(this.templates[templateIndex].body, false)
        }
    }

}

</script>


<style lang="scss">
.ProseMirror {
    border: 1px solid black;
    border-radius: .2em;
    padding: .5em;
    margin-bottom: 1em;
    max-height: 450px;
    min-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;

}

.ProseMirror:focus {
    outline: none;
}

.scopeTitle {
    display: inline-block;
}
</style>